import React from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { Grid, Box, Typography, Button } from "@mui/material"

import { SectionTitle } from "../SectionTitle"

import { StyledThankYou } from "./ThankYou.styled"

import ImgLogo from "../../images/web-logo.svg"

const ThankYou = ({ children }) => {
  return (
    <StyledThankYou>
      <Grid container>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <Box>
            <Box display="flex" justifyContent="center" mb={8}>
              <Box
                component="img"
                src={ImgLogo}
                alt="go touchless logo"
                sx={{ width: "300px" }}
              />
            </Box>
            <SectionTitle align="center">Thank you!</SectionTitle>
            <SectionTitle subtitle align="center">
              Your contact form has been submitted.
            </SectionTitle>
            <Typography align="center">
              A representative will be reaching out to you shortly.
            </Typography>
            <Box display="flex" justifyContent="center" mt={4}>
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate("/")}
                sx={{ color: "white" }}
              >
                Home
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
      </Grid>
    </StyledThankYou>
  )
}

ThankYou.propTypes = {
  children: PropTypes.node,
}

export default ThankYou
